import React from "react";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import "./footer.css";
import Box from '@mui/material/Box';
import { List, ListItem, ListItemText } from '@mui/material';
import FooterLogo from '../../../assets/logos/footerlogo.png';
import Insta from '../../../assets/logos/social/insta.png'
import Twitter from '../../../assets/logos/social/twitter.png'
import Space from '../../../assets/logos/social/space.png'
import Yt from '../../../assets/logos/social/yt.png'

function Footer() {
	return (
		<>
			<Box className="center-content footer-bg">
				<Grid container maxWidth={'lg'}>
					<Grid item md="12" sm="12" xs="12">
						<Grid container>
							<Grid item xl="3" lg="3" md="3" sm="6" xs="12">
								<Grid container direction="column" alignItems={'flex-start'} spacing={2}>
									<Grid item>
										<img src={FooterLogo} alt="" />
									</Grid>
									<Grid item>
										<List>
											<ListItem sx={{ p: 0 }}>
												<ListItemText primary={<Typography className="footerList" >Copyright @ 2024 Pricealgo</Typography>} />
											</ListItem>
											<ListItem sx={{ p: 0 }}>
												<ListItemText primary={<Typography className="footerList" >All rights reserved</Typography>} />
											</ListItem>
											<ListItem sx={{ padding: '9px 0px' }}>
												<Box sx={{display: 'flex', flexDirection: 'row', gap: '3px', justifyContent: 'space-betwwen'}}>
													<Avatar src={Insta} sx={{width: '30px', height: '30px'}}/>
													<Avatar src={Space} sx={{width: '30px', height: '30px'}}/>
													<Avatar src={Twitter} sx={{width: '32px', height: '30px'}}/>
													<Avatar src={Yt} sx={{width: '32px', height: '30px'}}/>
												</Box>
											</ListItem>
										</List>
									</Grid>
								</Grid>
							</Grid>
							<Grid xl="3" lg="3" md="3" sm="6" xs="12">
								<Grid container direction="column" alignItems={'flex-start'}>
									<Grid item>
										<Typography variant="h6" className="footerHeader">Company</Typography>
									</Grid>
									<Grid item>
										<List>
											<ListItem sx={{ p: 0 }}>
												<ListItemText primary={<Typography className="footerList">About Us</Typography>} />
											</ListItem>
											<ListItem sx={{ p: 0 }}>
												<ListItemText primary={<Typography className="footerList">Blog</Typography>} />
											</ListItem>
											<ListItem sx={{ p: 0 }}>
												<ListItemText primary={<Typography className="footerList">Contact Us</Typography>} />
											</ListItem>
											<ListItem sx={{ p: 0 }}>
												<ListItemText primary={<Typography className="footerList">Pricing</Typography>} />
											</ListItem>
											<ListItem sx={{ p: 0 }}>
												<ListItemText primary={<Typography className="footerList">Testimonials</Typography>} />
											</ListItem>

										</List>
									</Grid>
								</Grid>
							</Grid>
							<Grid xl="3" lg="3" md="3" sm="6" xs="12">
								<Grid container direction="column" alignItems={'flex-start'}>
									<Grid item>
										<Typography variant="h6" className="footerHeader">Support</Typography>
									</Grid>
									<Grid item>
										<List>
											<ListItem sx={{ p: 0 }}>
												<ListItemText primary={<Typography className="footerList">Help Center</Typography>} />
											</ListItem>
											<ListItem sx={{ p: 0 }}>
												<ListItemText primary={<Typography className="footerList">Terms of service</Typography>} />
											</ListItem>
											<ListItem sx={{ p: 0 }}>
												<ListItemText primary={<Typography className="footerList">Legal</Typography>} />
											</ListItem>
											<ListItem sx={{ p: 0 }}>
												<ListItemText primary={<Typography className="footerList">Privacy policy</Typography>} />
											</ListItem>
											<ListItem sx={{ p: 0 }}>
												<ListItemText primary={<Typography className="footerList">Status</Typography>} />
											</ListItem>

										</List>
									</Grid>
								</Grid>
							</Grid>
							<Grid xl="3" lg="3" md="3" sm="6" xs="12">
								<Grid container direction="column" alignItems={'flex-start'} spacing={2}>
									<Grid item>
										<Typography variant="h6" className="footerHeader">Stay up to date</Typography>
									</Grid>
									<Grid item>
										<List>
											<ListItem sx={{ p: 0 }}>
												<ListItemText primary={<Typography className="footerList">Techsquadrons</Typography>} />
											</ListItem>
											<ListItem sx={{ p: 0 }}>
												<ListItemText primary={<Typography className="footerList">G003, Ground Floor, Lifestyle Orchid,</Typography>} />
											</ListItem>
											<ListItem sx={{ p: 0 }}>
												<ListItemText primary={<Typography className="footerList">Sundarpur, Khandagiri-Chandaka Road,</Typography>} />
											</ListItem>
											<ListItem sx={{ p: 0 }}>
												<ListItemText primary={<Typography className="footerList">Bhubaneswar 754005, Odisha, India.</Typography>} />
											</ListItem>

										</List>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</>
	);
}

export default Footer;