import API from "../../services/api";
import Utils from '../../utils/utils';

export const pushNotification = async(payload) => {
    return API.put(Utils.getAPIDescription("push-notification"), payload).then(res => res.data);
}

export const fetchNotification = async(payload) => {
    return API.post(Utils.getAPIDescription("fetch-notification"), payload).then(res => res.data);
}

export const hideNotification = async(payload) => {
    return API.patch(Utils.getAPIDescription("hide-notification"), payload).then(res => res.data);
}

export const sendStatusEmail = async(payload) => {
    return API.post(Utils.getAPIDescription("send-status-email"), payload).then(res => res.data);
}