import Swal from "sweetalert2";
import getUserInfo from "../customhooks/user/useinfo";

class Utils {
    //Getting API description based on environment
    static getAPIDescription(signature) {
        const isLocal = window.location.href.includes('localhost');
        const apiGateWayLocal = 'http://localhost:3001/api';
        const apiGateWay = isLocal ? apiGateWayLocal : 'https://pricealgo.com/api';

        switch (signature) {
            case 'log-in':
                return `${apiGateWay}/login`;
            case 'sign-up':
                return `${apiGateWay}/signup`;
            case 'fetch-notification':
                return `${apiGateWay}/fetchnotification`;
            case 'push-notification':
                return `${apiGateWay}/pushnotification`;
            case 'hide-notification':
                return `${apiGateWay}/hidenotification`;
            case 'upload-profile-pic':
                return `${apiGateWay}/profilepicuploadtospace`;
            case 'get-profile':
                return `${apiGateWay}/getProfile`;
            case 'update-profile':
                return `${apiGateWay}/updateprofile`;
            case 'update-application-info':
                return `${apiGateWay}/updateapplicationinfo`;
            case 'contact-us':
                return `${apiGateWay}/contactUs`;
            case 'send-status-email':
                return `${apiGateWay}/sendstatusemail`;
            case 'admin-log-in':
                return `${apiGateWay}/adminLogIn`;
            case 'get-stock-info':
                return `${apiGateWay}/ticker-live-info`;
            case 'get-all-stock-info':
                return `${apiGateWay}/tickers-live-info`;
            default:
                return `${apiGateWay}/${signature}`;
        }
    }

    static getUserType = () => window.location.href.includes('/admin') ? 'admin' : 'user';

    static convertBase64 = file =>
        new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => resolve(fileReader.result);
            fileReader.onerror = reject;
        });

    static calculateAge = dob => {
        const today = new Date();
        const birthdate = new Date(dob);
        let age = today.getFullYear() - birthdate.getFullYear();
        const monthDiff = today.getMonth() - birthdate.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthdate.getDate())) {
            age--;
        }
        return age;
    };
}

export const getBaseUrl = () => {
    const env = process.env.REACT_APP_ENV || 'local';
    return env === 'DEV'
        ? 'http://localhost'
        : 'https://b82d8jivm0.execute-api.us-east-1.amazonaws.com';
};

export const apiResponseHandler = res => {
    if (res?.data?.status === 'success') {
        return res.data.data;
    } else {
        Swal.fire(res?.data?.message || 'Unknown error', '', 'error');
        throw new Error(res?.data?.message);
    }
};

export const getAllChildIds = node => {
    const tmpIds = [];
    const traverseRecursive = n => {
        tmpIds.push(n.id);
        if (Array.isArray(n.children)) {
            n.children.forEach(traverseRecursive);
        }
    };
    traverseRecursive(node);
    return tmpIds;
};

export const decodeKeyPass = (key, salt) => {
    const temp = key.substr(salt);
    return window.atob(temp);
};

export const hasSubscriptionExpired = () => {
    const userInfo = getUserInfo();
    const currentTime = Date.now();
    return !userInfo || !userInfo.subscription_end || userInfo.subscription_end < currentTime;
};

export const applicationStatusSequence = [
    'Pending',
    'Rejected',
    'Cancelled',
    'Non Practicing',
    'Active',
    'Practicing'
];


// Market schedule constants
const MARKET_OPEN_HOUR = 9;
const MARKET_OPEN_MINUTE = 30;
const MARKET_CLOSE_HOUR = 16;
const TIMEZONE_OFFSET = -5; // EST without daylight savings

const US_PUBLIC_HOLIDAYS = [
    '01-01', // New Year's Day
    '07-04', // Independence Day
    '12-25', // Christmas Day
    // Add more holidays as needed in 'MM-DD' format
];

// Check if it's a weekend
const isWeekend = (date) => {
    const day = date.getDay();
    return day === 0 || day === 6; // Sunday = 0, Saturday = 6
};

// Check if it's a public holiday
const isHoliday = (date) => {
    const formattedDate = `${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
    return US_PUBLIC_HOLIDAYS.includes(formattedDate);
};

// Check if the market is open based on time
const isMarketOpen = (date) => {
    const currentHour = date.getUTCHours() + TIMEZONE_OFFSET;
    const currentMinute = date.getUTCMinutes();

    if (currentHour > MARKET_OPEN_HOUR && currentHour < MARKET_CLOSE_HOUR) {
        return true;
    } else if (currentHour === MARKET_OPEN_HOUR && currentMinute >= MARKET_OPEN_MINUTE) {
        return true;
    } else if (currentHour === MARKET_CLOSE_HOUR && currentMinute < 0) {
        return true;
    }

    return false;
};

// Determine if we should fetch market data
export const shouldFetchMarketData = () => {
    const currentDate = new Date();

    if (isWeekend(currentDate) || isHoliday(currentDate)) {
        console.log('Market is closed: Weekend or Public Holiday.');
        return false;
    }

    if (!isMarketOpen(currentDate)) {
        console.log('Market is closed: Outside trading hours.');
        return false;
    }

    return true;
};

export default Utils;
